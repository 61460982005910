// @use "/src/Theme/custom.scss" as theme;
@import "/src/Theme/custom.scss";

.cards{
    width: auto;
    max-width: 100%;
    height: 100%;
    .login-card{
        background-color: $white;
        border-radius: 0;
        border: none;
        box-shadow: none;
        height: 100vh;
        @media only screen and (max-width: $md-screen) {
            height: 100%;
        }
        @media only screen and (max-width: $sm-screen) {
            height: auto;
            margin: 2rem auto;
            max-width: 450px;
            width: 90%;
        }
        .card-body{
            padding: 4.375rem;
            @media only screen and (max-width: $lg-screen) {
                padding: 3rem;
            }
            @media only screen and (max-width: $xs-screen) {
                padding: 1.5rem 1rem;
            }
        }
    }
    .off-white-card{
        border: none;
        border-radius: 8px;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.04);
        padding: 0;
    }
    .promotion-card{
        display: flex;
        flex-grow: 1;
        height: 100%;
        background-color: $white;
        border: 1px solid $gray-border;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        .card-body{
            padding: 1.5rem;
            @media only screen and (max-width: $lg-screen) {
                padding: 1.25rem;
            }
            @media only screen and (max-width: $sm-screen) {
                padding: 1rem;
            }
        }
    }
}