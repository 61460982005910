@import "/src/Theme/custom.scss";

.custom-accordion{
    width: auto;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $border-color;
    overflow: hidden;
    padding: 0.5rem;
    .accordion{
        .accordion-item{
            background-color: $secondary-color;
            border-radius: 8px;
            overflow: hidden;
            border: none;
            .accordion-header{
                .accordion-button{
                    background-color: $secondary-color;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 4rem;
                    box-shadow: none;
                    border-bottom: 1px solid transparent;
                    &::after{
                        position: absolute;
                        right: 10px;
                        width: 24px;
                        height: 24px;
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjI0MjIgMTIuNTE1NkMxNS41MjM0IDEyLjIzNDQgMTUuNTIzNCAxMS43NjU2IDE1LjI0MjIgMTEuNDg0NEwxMC45OTIyIDcuMjM0MzhDMTAuNjc5NyA2LjkyMTg4IDEwLjIxMDkgNi45MjE4OCA5LjkyOTY5IDcuMjM0MzhMOS4yMTA5NCA3LjkyMTg4QzguOTI5NjkgOC4yMzQzOCA4LjkyOTY5IDguNzAzMTIgOS4yMTA5NCA4Ljk4NDM4TDEyLjI0MjIgMTIuMDE1Nkw5LjIxMDk0IDE1LjAxNTZDOC45Mjk2OSAxNS4yOTY5IDguOTI5NjkgMTUuNzY1NiA5LjIxMDk0IDE2LjA3ODFMOS45Mjk2OSAxNi43NjU2QzEwLjIxMDkgMTcuMDc4MSAxMC42Nzk3IDE3LjA3ODEgMTAuOTkyMiAxNi43NjU2TDE1LjI0MjIgMTIuNTE1NloiIGZpbGw9IiNCOUI5QjkiLz4KPC9zdmc+Cg==);
                        background-size: 24px;
                    }
                    &:not(.collapsed){
                        box-shadow: none;
                        border-bottom: 1px solid $border-color;
                        &::after{
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
    }
}