// @use "/src/Theme/custom.scss" as theme;
@import "/src/Theme/custom.scss";

.dialogue-children {
    padding: 45px 0;
    display: flex;
    width: 500px;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: $secondary-color;
    flex-direction: column;
    border-radius: 16px;
    .dialogue-title {
        text-align: center;
        .typography {
            p {
                margin: auto;
            }
        }
    }
    .dialogue-buttons{
        display: flex;
        justify-content: space-between;
        margin-top: 35px;
        gap:1rem;
    }


}
