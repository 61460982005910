@import "/src/Theme/custom.scss";

$gold: #ffc01e;
$silver: #a0a0a0;
$platinum: #57addd;
$white: #ffffff;

.navbar-brand {
    display: inline-flex;
    padding: 0;
    margin: 0;
}
.avatars {
    width: auto;
    max-width: 100%;
    position: relative;
    img {
        object-fit: contain;
        width: auto;
        max-width: 100%;
        height: auto;
        display: inline-block;
    }
    .sidebar-logo {
        height: 6.25rem;
        width: 9.938rem;
        margin: auto;
        justify-content: center;
        display: flex;
        @media only screen and (max-width: $sm-screen) {
            height: auto;
        }
    }
    .gif-loader {
        width: 500px;
        max-width: 100%;
        mix-blend-mode: darken;
    }
    .product-details {
        max-width: 100%;
        height: 300px;
        padding: 0;
    }

    .logo-login {
        height: 12.5rem;
    }
    .profile-image {
        width: 6.75rem;
        min-width: 6.75rem;
        height: 6.75rem;
        border-radius: 6.75rem;
        transition: all 0.3s ease;
        display: block;
        object-fit: cover;
    }
    .profile-image-md {
        width: 90px;
        height: 90px;
        min-width: 90px;
        border-radius: 90px;
        .profile-image {
            width: 100%;
            height: 100%;
            min-width: 90px;
        }
        &[membership="silver"],
        &[membership="diamond"],
        &[membership="gold"],
        &[membership="platinum"] {
            border: none;
            img {
                border: none;
            }
            &::after {
                left: unset;
                bottom: unset;
                margin: 0;
                top: -1px;
                right: -3px;
            }
        }
    }
    .header-pro-thumb {
        width: 40px;
        height: 40px;
        min-width: 40px;
        border-radius: 40px;
        transition: all 0.3s ease;
        display: block;
        object-fit: cover;
        cursor: pointer;
    }
    div {
        position: relative;
        &[membership="gold"] {
            border: 4px solid $gold;
            border-radius: 150px;
            img {
                border: 2px solid $white;
            }
            &::after {
                content: "";
                width: 26px;
                height: 28px;
                display: block;
                background-image: url("../../../public/images/badge-gold-sm.svg");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -15px;
                margin: 0 auto;
            }
        }
        &[membership="silver"] {
            border: 4px solid $silver;
            border-radius: 150px;
            img {
                border: 2px solid $white;
            }
            &::after {
                content: "";
                width: 28px;
                height: 29px;
                display: block;
                background-image: url("../../../public/images/badge-silver-sm.svg");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -15px;
                margin: 0 auto;
            }
        }
        &[membership="platinum"] {
            border: 4px solid $platinum;
            border-radius: 150px;
            img {
                border: 2px solid $white;
            }
            &::after {
                content: "";
                width: 27px;
                height: 29px;
                display: block;
                background-image: url("../../../public/images/badge-platinum-sm.svg");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -15px;
                margin: 0 auto;
            }
        }
        &[membership="diamond"] {
            border: 4px solid $platinum;
            border-radius: 150px;
            img {
                border: 2px solid $white;
            }
            &::after {
                content: "";
                width: 27px;
                height: 29px;
                display: block;
                background-image: url("../../../public/images/badge-diamond-sm.svg");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -15px;
                margin: 0 auto;
            }
        }
        &.profile-image-no-border{
            &[membership="silver"],
            &[membership="gold"],
            &[membership="platinum"] {
                border: none;
                img {
                    border: none;
                }
            }
        }
    }
    
    .table-product-image {
        width: 3.125rem;
        min-width: 3.125rem;
        height: 3.125rem;
        border-radius: 4px;
        border: 1px solid $border-color;
        object-fit: cover;
    }
    .order-image {
        // width: 100px;
        min-width: 100px;
        max-height: 66px;
        border-radius: 8px;
        border: 1px solid $border-color;
        object-fit: cover;
    }
    .order-detail-thumb {
        min-width: 100px;
        max-height: 66px;
        object-fit: contain;
    }
    .promotion-detail {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .quick-promotion-list {
        width: 4.375rem;
        min-width: 4.375rem;
        max-height: 2.5rem;
        border-radius: 3px;
        object-fit: contain;
        background-color: rgba($color: $info-color, $alpha: 0.3);
        &.upload-img{
            height: 2.25rem;
            width: 2.75rem;
            min-width: 2.75rem;
            border-radius: 0.5rem;
        }
    }

    .active{
        position: relative;
        &::after{
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 0.8rem;
            display: block;
            content: '';
            background-color: $success-color;
            position: absolute;
            right: 1px;
            top: 2px;
        }
    }
}
