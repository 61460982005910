@import "/src/Theme/custom.scss";

.carousel {
    width: auto;
    .slick-slider {
        background-color: $white;
        padding: 1.25rem;
        border: 1px solid $gray-border;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
       
        .slick-list {
            margin-bottom: 0.5rem;
            .slick-slide {
                .active-images {
                    width: 450px;
                    max-width: 100%;
                    height: 300px;
                    .avatars {
                        height: 100%;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 8px;
                        }
                    }
                }
            }
        }
        .slick-dots {
            position: relative;
            bottom: 0;
            display: flex !important;
            overflow: hidden;
            overflow-x: auto;
            justify-content: center;
            li {
                margin: 0.25rem;
                &.slick-active {
                    .image-options {
                        border: 3px solid $info-color;
                    }
                }
                display: inline-block;
                width: 60px;
                min-width: 60px;
                height: 60px;
                border: 0;
                @media only screen and (max-width: $xl-screen) {
                    width: 45px;
                    min-width: 45px;
                    height: 45px;
                }
                .image-options {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    overflow: hidden;
                    .avatars {
                        height: 100%;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            opacity: 90%;
                        }
                    }
                }
            }
        }
        &.promo-details-card {
            box-shadow: none;
            border-radius: 0;
            max-width: 320px;
            margin: 0 auto;
            @media (max-width:992.98px) {
                max-width: unset;
            }
            .slick-list {
                margin-bottom: 0;
                .active-images {
                    height: 100%;
                    height: 380px;
                    .avatars {
                        height: 100%;
                        img {
                            border-radius: 0;
                            object-position: center;
                            height: 100%;
                        }
                    }
                }
            }
        }
        &.quick-promotions {
            box-shadow: none;
            border-radius: 3px;
            overflow: hidden;
            width: 4.375rem;
            height: 2.5rem;
            display: inline-block;
            margin: 0 auto;
            border: none;
           &.slick-initialized {
            display: inline-block;
           }

            .slick-list {
                margin-bottom: 0;
                display: list-item;
                height: 100%;
                .active-images {
                    width: 4.375rem;
                    min-width: 4.375rem;
                    max-height: 2.5rem;
                    height: 2.5rem;
                    .avatars {
                        height: 100%;
                        width: 100%;
                        img {
                            border-radius: 0;
                            object-position: center;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
                .slick-track {
                    height: 100%;
                    .slick-slide {
                        display: inline-block;
                    }
                }
            }
        }
        &.quick-promotions-list-items{
            box-shadow: none;
            border-radius: 2px;
            display: block;
            margin: 0;
            padding: 0;
            background-color: transparent;
            border: none;
            &.slick-initialized {
                display: block;
            }
            .slick-list {
                margin-bottom: 0;
                display:inline-block;
                width: 24px;
                height: 100%;
                .slick-track{
                    height: 100%;
                    .slick-slide {
                        display: block;
                        &>div{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .active-images {
                    width: 24px;
                    height: 100%;
                    max-height: 30px;
                    .avatars {
                        height: 100%;
                        width: 100%;
                        img {
                            border-radius: 0;
                            object-position: center;
                            height: 100%;
                            width: 100%;
                            min-width: 100%;
                        }
                    }
                }
            }
        }
    }
    &.quick-carousal{
        height: 2.5rem;
    }
}
