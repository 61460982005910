@import "/src/Theme/custom.scss";

.shipping-details {
    width: 100%;
    .title{
        svg{
            *{
                fill: $primary-color;
            }
        }
    }
}
