@import "/src/Theme/custom.scss";
.appbar {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 3.5rem;
    background-color: $secondary-color;
    border-bottom: 1px solid $border-color;
    box-shadow: 0px 1px 1px $white;
    z-index: 10;
    @media only screen and (max-width: $lg-screen) {
        padding: 1rem 2rem;
    }
    @media only screen and (max-width: $sm-screen) {
        padding: 0.75rem 1rem;
    }
    .appbar-left {
        h2 {
            margin: 0;
            @media only screen and (max-width: $sm-screen) {
                font-size: 1.25rem;
                line-height: 1.5rem;
            }
        }
        .title {
            text-transform: capitalize;
        }
    }
    div {
        .profile {
            position: relative;
            display: contents;
            .dropdown-toggle {
                color: $black !important;
                padding-left: 0.5rem;
                display: flex;
                align-items: center;
                &::after{
                    content: '';
                    background-image: url('../../../public/images/header_dropdown.svg');
                    border: 0px;
                    width: 1rem;
                    height: 1rem;
                    min-width: 1rem;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
            .dropdown-menu {
                border: 1px solid $border-color;
                border-radius: 10px;
                &::before,&::after{
                    content: '';
                    border-top: 0;
                    border-right: 0.5em solid transparent;
                    border-bottom: 0.5em solid #ffffff;
                    border-left: 0.5em solid transparent;
                    position: absolute;
                    right: 12px;
                    top: -8px;
                }
                &::before{
                    border-bottom: 0.5em solid #D7D7D7;
                    top: -9px;
                }
            }
            .menu-options {
                padding: 5px 15px;
                .dropdown-item {
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 16px;
                    color: $text-gray;
                    &:hover {
                        background-color: unset;
                    }
                }
                &:hover {
                    background-color: rgba(242, 113, 116, 0.06);
                    svg {
                        * {
                            fill: $primary-color;
                        }
                    }
                    .dropdown-item {
                        color: $primary-color;
                    }
                }
            }
        }
    }
}
