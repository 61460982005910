@import "/src/Theme/custom.scss";

.react-datepicker-wrapper{
    .react-datepicker__input-container{
        input[type="text"]{
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: $dark01;
            background-color: $white;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            appearance: none;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            &:focus,&:focus-visible{
                outline: none;
            }
        }
        position: relative;
    }
    position: relative;
}

.gray-form{
    .react-datepicker-wrapper{
        .react-datepicker__input-container{
            position: relative;
            input[type="text"]{
                background-color: $form-disabled;
                border-color: $border-color;
                border-radius: 0.5rem;
                padding: 0.695rem 2.25rem 0.695rem 0.75rem;
                font-weight: $font-semibold;
                font-size: 0.813rem;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 19.3125C4 20.2617 4.75 21 5.71429 21H18.2857C19.2143 21 20 20.2617 20 19.3125V9.75H4V19.3125ZM15.4286 12.4219C15.4286 12.2109 15.6071 12 15.8571 12H17.2857C17.5 12 17.7143 12.2109 17.7143 12.4219V13.8281C17.7143 14.0742 17.5 14.25 17.2857 14.25H15.8571C15.6071 14.25 15.4286 14.0742 15.4286 13.8281V12.4219ZM15.4286 16.9219C15.4286 16.7109 15.6071 16.5 15.8571 16.5H17.2857C17.5 16.5 17.7143 16.7109 17.7143 16.9219V18.3281C17.7143 18.5742 17.5 18.75 17.2857 18.75H15.8571C15.6071 18.75 15.4286 18.5742 15.4286 18.3281V16.9219ZM10.8571 12.4219C10.8571 12.2109 11.0357 12 11.2857 12H12.7143C12.9286 12 13.1429 12.2109 13.1429 12.4219V13.8281C13.1429 14.0742 12.9286 14.25 12.7143 14.25H11.2857C11.0357 14.25 10.8571 14.0742 10.8571 13.8281V12.4219ZM10.8571 16.9219C10.8571 16.7109 11.0357 16.5 11.2857 16.5H12.7143C12.9286 16.5 13.1429 16.7109 13.1429 16.9219V18.3281C13.1429 18.5742 12.9286 18.75 12.7143 18.75H11.2857C11.0357 18.75 10.8571 18.5742 10.8571 18.3281V16.9219ZM6.28571 12.4219C6.28571 12.2109 6.46429 12 6.71429 12H8.14286C8.35714 12 8.57143 12.2109 8.57143 12.4219V13.8281C8.57143 14.0742 8.35714 14.25 8.14286 14.25H6.71429C6.46429 14.25 6.28571 14.0742 6.28571 13.8281V12.4219ZM6.28571 16.9219C6.28571 16.7109 6.46429 16.5 6.71429 16.5H8.14286C8.35714 16.5 8.57143 16.7109 8.57143 16.9219V18.3281C8.57143 18.5742 8.35714 18.75 8.14286 18.75H6.71429C6.46429 18.75 6.28571 18.5742 6.28571 18.3281V16.9219ZM18.2857 5.25H16.5714V3.5625C16.5714 3.28125 16.2857 3 16 3H14.8571C14.5357 3 14.2857 3.28125 14.2857 3.5625V5.25H9.71429V3.5625C9.71429 3.28125 9.42857 3 9.14286 3H8C7.67857 3 7.42857 3.28125 7.42857 3.5625V5.25H5.71429C4.75 5.25 4 6.02344 4 6.9375V8.625H20V6.9375C20 6.02344 19.2143 5.25 18.2857 5.25Z' fill='%23B9B9B9'/%3E%3C/svg%3E");
                background-position: right 10px center;
                background-repeat: no-repeat;
                background-size: 1.5rem;
                &::placeholder{
                    color: $light-gray;
                    font-weight: $font-semibold;
                    font-size: 0.813rem;
                }
            }
        }
    }
}

.react-datepicker__tab-loop{
    position: relative;
    .react-datepicker-popper{
        position: absolute;
        right: 0 !important;
        left: auto !important;
        .react-datepicker{
            border-color: $border-color;
            button{
                &.react-datepicker__navigation{
                    &::hover{
                        *{
                            &::before{
                                border-color: $info-color;
                            }
                        }
                    }
                }
            }
            .react-datepicker__month-container{
                .react-datepicker__header{
                    border-bottom-color: $border-color;
                }
                .react-datepicker__month{
                    .react-datepicker__week{
                        .react-datepicker__day{
                            &:hover,&:focus,&:focus-visible{
                                color: $primary-color;
                            }
                            &.react-datepicker__day--today{
                                background-color: rgba($color: $info-color, $alpha: 0.3);
                                border-radius: 0.3rem;
                                color: $info-color;
                            }
                            &.react-datepicker__day--selected,&.react-datepicker__day--keyboard-selected{
                                background-color: $primary-color;
                                border-radius: 0.3rem;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}