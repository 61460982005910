@use "../../../src/Theme/custom.scss" as theme;

.authentication{
    display: flex;
    flex-direction: column;
    background-color: theme.$secondary-color;
    min-height: 100vh;
    width: 100vw;
    padding: 0;
    .auth-col{
        height: auto;
        @media (max-width:768.98px) {
            height: 50%;
        }
        @media (min-width:992.98px) {
            height: auto;
        }
    }
    .auth-row{
        height: 100%;
        @media (min-width:768.98px) {
            height: 100vh;
        }
        @media (min-width:992.98px) {
            height: 100%;
        }
    }
}
.auth-bg{
    background-image: url(../../../public/images/auth-bg.jpg);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: cover;
}