
@import "/src/Theme/custom.scss";

.tabs {
    width: 100%;
    height: 100%;
    .tab-button-type {
        .nav-tabs {
            border-bottom: 0;
            .nav-item {
                margin-right: 0.5rem;
                .nav-link {
                    color: $text-gray;
                    font-size: 1rem;
                    line-height: 1.25rem;
                    font-weight: $font-semibold;
                    border-radius: 8px;
                    border: none;
                    outline: none;
                    &:hover {
                        color: $info-color;
                    }
                }
                .active {
                    background-color: $secondary-color;
                    box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
                    color: $info-color;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .tab-with-border {
        height: 100%;
        .nav-tabs {
            border-bottom: 1px solid $border-color;
            .nav-item {
                .nav-link {
                    color: $text-gray;
                    font-size: 0.813rem;
                    line-height: 1.25rem;
                    font-weight: $font-semibold;
                    border-radius: 0px;
                    border: none;
                    outline: none;
                    background: none;
                    padding: 0.5rem 1.25rem;
                    &:hover {
                        color: $info-color;
                    }
                }
                .active {
                    color: $primary-color;
                    background: none;
                    position: relative;
                    border: 0;
                    overflow: visible;
                    &::after{
                        content: '';
                        width: 100%;
                        height: 3px;
                        bottom: -2px;
                        left: 0;
                        position: absolute;
                        background-color: $primary-color;
                        border-radius: 2px;
                    }
                    &:hover {
                        color: $primary-color;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        .tab-content{
            height: 60%;
            flex-grow: 1;
            .tab-pane{
                height: 100%;
                .orders-tab{
                    height: 100%;
                    &>.table{
                        height: 100%;
                        margin-bottom: 0;
                        .custom-table{
                            .search-badges{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}