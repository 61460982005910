// Colors
$primary-color  : #EC2B2F;
$secondary-color: #F1F1F1;
$danger-color   : #EC2B2F;
$info-color     : #F27174;
$info-light     : #FBD4D5;
$dark01         : #212121;
$text-gray      : #818181;
$text-gray02    : #959EAE;
$text-gray03    : #616161;
$black          : #000000;
$white          : #ffffff;
$gold           : #FFC01E;
$silver         : #A0A0A0;
$platinum       : #57ADDD;
$success-color  : #00B16A;
$light-gray     : #B9B9B9;
$border-color   : #D7D7D7;
$gray-border    : #E5E5E5;
$medium-border  : #EAE5E0;
$light-border   : #FAFAFA;
$form-disabled  : #F5F5F5;
$silver-text    : #BBBBBB;
$platinum-text  : #57ADDD;
$gold-text      : #BDA26F;
$blue           : #5D61CD;
$light-blue     : #2697FE;
$purple         : #9C4FCC;
$cyan           : #7FC8DD;
$pink           : #D75CC3;
$purple01       : #C257F8;
$warning-color  : #FF7A36;


$theme-colors: (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "info": $info-color,
    "danger": $danger-color,
    "success": $success-color,
    "dark": $dark01,
    "black": $black,
    "white": $white
);

// Font Weight
$font-light          : 300;
$font-regular        : 400;
$font-medium         : 500;
$font-semibold       : 600;
$font-bold           : 700;
$font-extrabold      : 800;


// Responsive Screens
$hd-screen           : 1700px;
$xl-screen           : 1399.98px;
$lg-screen           : 1199.98px;
$md-screen           : 991.98px;
$sm-screen           : 767.98px;
$min-sm-screen       : 768px;
$xs-screen           : 575.98px;
$min-md-screen       : 992px;
$min-lg-screen       : 1200px;
$max-xs-screen       : 575px;