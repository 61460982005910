@import "/src/Theme/custom.scss";

.custom-checkbox{
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    .form-check{margin-bottom: 0;}
    input[type="checkbox"]{
        width: 24px;
        height: 24px;
        min-width: 24px;
        border: 1px solid $text-gray;
        background-color: $white;
        box-shadow: none;
        outline: none;
        cursor: pointer;
        margin-top: 0;
        &:checked{
            background-color: $primary-color;
            border-color: $primary-color;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzE3MDZfMTgwMjYpIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjc5MzA0IDcuNzE3NDVDMi4xODM1NyA3LjMyNjkzIDIuODE2NzMgNy4zMjY5MyAzLjIwNzI2IDcuNzE3NDVMNS42ODIxMyAxMC4xOTIzTDEyLjM5OTYgMy40NzQ4MUMxMi43OTAyIDMuMDg0MjkgMTMuNDIzMyAzLjA4NDI5IDEzLjgxMzkgMy40NzQ4MUMxNC4yMDQ0IDMuODY1MzQgMTQuMjA0NCA0LjQ5ODUgMTMuODEzOSA0Ljg4OTAzTDYuMzg5MjQgMTIuMzEzNkM1Ljk5ODcxIDEyLjcwNDIgNS4zNjU1NSAxMi43MDQyIDQuOTc1MDIgMTIuMzEzNkwxLjc5MzA0IDkuMTMxNjdDMS40MDI1MiA4Ljc0MTE0IDEuNDAyNTIgOC4xMDc5OCAxLjc5MzA0IDcuNzE3NDVaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzE3MDZfMTgwMjYiPgo8cmVjdCB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==");
            background-size: 15px;
            background-position: center;
            background-repeat: no-repeat;
            box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
        }
    }
    .labelled,.labelled-options{
        display: inline-flex;
        .form-check{
            display: inline-flex;
            align-items: center;
            gap: 0.5rem;
            .form-check-label{
                color: $text-gray;
                font-size: 12px;
                font-weight: $font-medium;
                cursor: pointer;
                text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.5), 1px 1px 1px rgba(0, 0, 0, 0.1);
                margin: 0;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none;
                line-height: 16px;
            }
            input[type="checkbox"]{
                &:checked{
                    &~.form-check-label{
                        color: $primary-color;
                    }
                }
            }
        }
    }

}