@import "/src/Theme/custom.scss";
.chat-container{
    @media only screen and (max-width: $md-screen) {
        position: absolute;
    }
}
.chat-body {
    &>.cards{
        height: 100%;
        &>.card{
            height: 100%;
            background-color: $white;
            border-radius: 0.5rem;
            &>.card-body{
                height: 100%;
                display: flex;
                flex-flow: column;
                padding: 0;
            }
        }
    }
    hr{
        margin: 0.5rem 0;
        background-color: $gray-border;
        opacity: 1;
    }
    .chat-box{
        border: none;
        background-color: $secondary-color;
        border-radius: 6px;
        .card-body{
            padding: 0.3rem 3.5rem 1.25rem 0.625rem;
        }
        &.chat-me{
            background-color: $info-color;
            .chat-name{color: $white;}
            .chat-message{color: $white;}
            .chat-time{
                p{color: $white;}
            }
        }
        &.arrow-left{
            border-top-left-radius: 0;
            &::before {
                content: " ";
                position: absolute;
                left: -10px;
                top: 0;
                border-top: 0px solid transparent;
                border-right: 10px solid #F1F1F1;
                border-left: none;
                border-bottom: 10px solid transparent;
            }
        }
        &.arrow-right{
            border-top-right-radius: 0;
            &::after {
                content: " ";
                position: absolute;
                right: -10px;
                top: 0;
                border-top: 0px solid transparent;
                border-right: none;
                border-left: 10px solid #F27174;
                border-bottom: 10px solid transparent;
            }
        }
        .chat-name{color: $dark01;}
        .chat-message{color: $dark01;}
        .chat-time{
            position: absolute;
            right: 8px;
            bottom: 2px;
            p{color: $text-gray;}
        }
    }
    .chat-dropdown{
        .dropdown-menu {
            border: 1px solid $border-color;
            border-radius: 10px;
        }
        .menu-options {
            padding: 5px;
            .dropdown-item {
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                color: $text-gray;
                &:hover {
                    background-color: unset;
                }
            }
            &:hover {
                background-color: rgba(242, 113, 116, 0.06);
                svg {
                    * {
                        fill: $primary-color;
                    }
                }
                .dropdown-item {
                    color: $primary-color;
                }
            }
        }
    }
    .chat-editor{
        input{
            &.form-control{
                border: none;
                background-color: transparent;
                border-radius: 0;
                font-size: 0.938rem;
                font-weight: $font-medium;
                color: $dark01;
                padding: 0.375rem;
                min-height: 3rem;
                &::placeholder{
                    font-size: 0.938rem;
                    font-weight: $font-medium;
                    color: $text-gray;
                }
            }
        }
    }
    .custom-tag{
        padding: 4px;
        height: 26px;
        background: #F27174;
        border-radius: 4px;
        color: #ffffff;
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
    }
}