@import "/src/Theme/custom.scss";

.chat-trigger{
    .search{
        .search-container{
            width: 100%;
            background-color: $form-disabled;
            svg{
                *{
                    fill: $light-gray;
                }
            }
            input.form-control{
                background-color: $form-disabled;
                &::placeholder{
                    color: $light-gray;
                }
            }
        }
    }
}

.chat-head{
    .chat-each{
        cursor: pointer;
    }
    .chat-list-box{
        width: calc(100% - 112px);
        .avatars{
            &::after{
                display: none !important;
            }
        }
        .people-name{
            .typography{
                width: 100%;
            }
        }
    }
}