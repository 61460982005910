@import "/src/Theme/custom.scss";

.modal-content{
    .modal-body{
        .image-cropper{
            margin: -1.5rem;
            .cropper-container{max-width: 100%;}
            img{
                max-height: 60vh;
                object-fit: contain;
            }
        }
    }
}