@import "/src/Theme/custom.scss";
.table {
    background-color: $secondary-color;
    thead,
    tbody,
    tr,
    td,
    th {
        border: none !important;
        border-width: 0;
    }
    &.bordered{
        td,
        th {
            border: 1px solid $border-color !important;
        }
    }
    .custom-table {
        height: 100%;
        display: flex;
        flex-flow: column;
        .table-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            flex-wrap: wrap;
            button.btn {
                padding-top: 0.7rem;
                padding-bottom: 0.7rem;
            }
            & > * {
                padding: 0;
            }
        }
        .table-body {
            .filter-slider{
                padding-left: 0px;
                padding-right: 0px;
                .search-filter-container {
                    width: 100%;
                    padding: 0;
                    margin: 10px auto auto auto;
                    display: flex;
                    flex-flow: row;
                    overflow: hidden;
                    padding-bottom: 190px;
                    margin-bottom: -190px;
                    scroll-behavior: smooth;
                    .filter-container {
                        position: relative;
                        .filter-title {
                            font-weight: $font-semibold;
                            font-size: 13px;
                            line-height: 24px;
                            letter-spacing: 0.03em;
                            color: $text-gray;
                            text-transform: capitalize;
                        }
                        .dropdown{
                            .dropdown-menu{
                                max-height: 180px;
                                overflow: hidden;
                                overflow-y: auto;
                            }
                        }
                    }
                }
            }
        }
        .react-bootstrap-table {
            padding: 0;
            height: 40%;
            width: 100%;
            flex-grow: 1;
            overflow: auto;
            position: relative;
            margin-bottom: 1rem;
            transition: 0.3s ease all;
            table.table {
                margin: 0;
                thead {
                    position: sticky;
                    top: 5px;
                    left: 0;
                    z-index: 1;
                    transform: translateY(-5px);
                    background-color: $secondary-color;
                    tr {
                        .expand-cell-header {
                            display: none;
                        }
                    }
                }
                tbody {
                    tr {
                        .expand-cell {
                            display: none;
                        }
                    }
                }
                border-collapse: separate;
                border-spacing: 0px 5px;
                padding: 0;
                tr {
                    th {
                        font-size: 0.813rem;
                        line-height: 1.25rem;
                        padding: 1rem 0.5rem;
                        color: $text-gray02;
                        font-weight: $font-semibold;
                        vertical-align: middle;
                        border: none;
                        box-shadow: none;
                        white-space: nowrap;
                        -webkit-touch-callout: none; /* iOS Safari */
                        -webkit-user-select: none; /* Safari */
                        -khtml-user-select: none; /* Konqueror HTML */
                        -moz-user-select: none; /* Old versions of Firefox */
                        -ms-user-select: none; /* Internet Explorer/Edge */
                        user-select: none; /* Non-prefixed version, currently */
                        &.sortable {
                            .order {
                                width: 16px;
                                height: 16px;
                                display: inline-block;
                                margin-left: 4px;
                                cursor: pointer;
                                background-position: center;
                                background-size: contain;
                                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuOTczNTQgMTQuMjk2OUM1Ljk3MzU0IDE0LjcwNyA1LjY4MDU3IDE1IDUuMjcwNDEgMTVINC44MDE2NkM0LjQyMDggMTUgNC4wOTg1NCAxNC43MDcgNC4wOTg1NCAxNC4yOTY5VjUuNzVIMi42OTIyOUMyLjA3NzA1IDUuNzUgMS43NTQ3OSA1LjAxNzU4IDIuMjIzNTQgNC41NzgxMkw0LjU2NzI5IDIuMjM0MzhDNC44MzA5NiAxLjk0MTQxIDUuMjcwNDEgMS45NDE0MSA1LjUzNDA4IDIuMjM0MzhMNy44Nzc4MyA0LjU3ODEyQzguMzE3MjkgNS4wMTc1OCA4LjAyNDMyIDUuNzUgNy4zNzk3OSA1Ljc1SDUuOTczNTRWMTQuMjk2OVoiIGZpbGw9IiNCOUI5QjkiLz4KPHBhdGggZD0iTTEwLjE4NTkgMi43MDMxMlYxMS4yNUg4Ljc3OTY5QzguMTY0NDUgMTEuMjUgNy44NDIxOSAxMi4wMTE3IDguMzEwOTQgMTIuNDUxMkwxMC42NTQ3IDE0Ljc5NDlDMTAuOTE4NCAxNS4wODc5IDExLjM1NzggMTUuMDg3OSAxMS42MjE1IDE0Ljc5NDlMMTMuOTY1MiAxMi40NTEyQzE0LjQwNDcgMTIuMDExNyAxNC4xMTE3IDExLjI1IDEzLjQ2NzIgMTEuMjVIMTIuMDYwOVYyLjcwMzEyQzEyLjA2MDkgMi4zMjIyNyAxMS43NjggMiAxMS4zNTc4IDJIMTAuODg5MUMxMC41MDgyIDIgMTAuMTg1OSAyLjMyMjI3IDEwLjE4NTkgMi43MDMxMloiIGZpbGw9IiNCOUI5QjkiLz4KPC9zdmc+Cg==");
                            }
                            .react-bootstrap-table-sort-order {
                                margin-left: 4px;
                                cursor: pointer;
                                .caret {
                                    width: 16px;
                                    height: 16px;
                                    display: inline-block;
                                    background-position: center;
                                    background-size: contain;
                                    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuOTczNTQgMTQuMjk2OUM1Ljk3MzU0IDE0LjcwNyA1LjY4MDU3IDE1IDUuMjcwNDEgMTVINC44MDE2NkM0LjQyMDggMTUgNC4wOTg1NCAxNC43MDcgNC4wOTg1NCAxNC4yOTY5VjUuNzVIMi42OTIyOUMyLjA3NzA1IDUuNzUgMS43NTQ3OSA1LjAxNzU4IDIuMjIzNTQgNC41NzgxMkw0LjU2NzI5IDIuMjM0MzhDNC44MzA5NiAxLjk0MTQxIDUuMjcwNDEgMS45NDE0MSA1LjUzNDA4IDIuMjM0MzhMNy44Nzc4MyA0LjU3ODEyQzguMzE3MjkgNS4wMTc1OCA4LjAyNDMyIDUuNzUgNy4zNzk3OSA1Ljc1SDUuOTczNTRWMTQuMjk2OVoiIGZpbGw9IiM5NTlFQUUiLz4KPHBhdGggZD0iTTEwLjE4NTkgMi43MDMxMlYxMS4yNUg4Ljc3OTY5QzguMTY0NDUgMTEuMjUgNy44NDIxOSAxMi4wMTE3IDguMzEwOTQgMTIuNDUxMkwxMC42NTQ3IDE0Ljc5NDlDMTAuOTE4NCAxNS4wODc5IDExLjM1NzggMTUuMDg3OSAxMS42MjE1IDE0Ljc5NDlMMTMuOTY1MiAxMi40NTEyQzE0LjQwNDcgMTIuMDExNyAxNC4xMTE3IDExLjI1IDEzLjQ2NzIgMTEuMjVIMTIuMDYwOVYyLjcwMzEyQzEyLjA2MDkgMi4zMjIyNyAxMS43NjggMiAxMS4zNTc4IDJIMTAuODg5MUMxMC41MDgyIDIgMTAuMTg1OSAyLjMyMjI3IDEwLjE4NTkgMi43MDMxMloiIGZpbGw9IiNCOUI5QjkiLz4KPC9zdmc+Cg==");
                                }
                                &.dropup {
                                    .caret {
                                        background-position: center;
                                        background-size: contain;
                                        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuOTczNTQgMTQuMjk2OUM1Ljk3MzU0IDE0LjcwNyA1LjY4MDU3IDE1IDUuMjcwNDEgMTVINC44MDE2NkM0LjQyMDggMTUgNC4wOTg1NCAxNC43MDcgNC4wOTg1NCAxNC4yOTY5VjUuNzVIMi42OTIyOUMyLjA3NzA1IDUuNzUgMS43NTQ3OSA1LjAxNzU4IDIuMjIzNTQgNC41NzgxMkw0LjU2NzI5IDIuMjM0MzhDNC44MzA5NiAxLjk0MTQxIDUuMjcwNDEgMS45NDE0MSA1LjUzNDA4IDIuMjM0MzhMNy44Nzc4MyA0LjU3ODEyQzguMzE3MjkgNS4wMTc1OCA4LjAyNDMyIDUuNzUgNy4zNzk3OSA1Ljc1SDUuOTczNTRWMTQuMjk2OVoiIGZpbGw9IiNCOUI5QjkiLz4KPHBhdGggZD0iTTEwLjE4NTkgMi43MDMxMlYxMS4yNUg4Ljc3OTY5QzguMTY0NDUgMTEuMjUgNy44NDIxOSAxMi4wMTE3IDguMzEwOTQgMTIuNDUxMkwxMC42NTQ3IDE0Ljc5NDlDMTAuOTE4NCAxNS4wODc5IDExLjM1NzggMTUuMDg3OSAxMS42MjE1IDE0Ljc5NDlMMTMuOTY1MiAxMi40NTEyQzE0LjQwNDcgMTIuMDExNyAxNC4xMTE3IDExLjI1IDEzLjQ2NzIgMTEuMjVIMTIuMDYwOVYyLjcwMzEyQzEyLjA2MDkgMi4zMjIyNyAxMS43NjggMiAxMS4zNTc4IDJIMTAuODg5MUMxMC41MDgyIDIgMTAuMTg1OSAyLjMyMjI3IDEwLjE4NTkgMi43MDMxMloiIGZpbGw9IiM5NTlFQUUiLz4KPC9zdmc+Cg==");
                                    }
                                }
                            }
                        }
                    }
                    td {
                        background-color: $white;
                        font-size: 0.813rem;
                        line-height: 1.25rem;
                        padding: 1rem 0.5rem;
                        color: $text-gray03;
                        font-weight: $font-semibold;
                        vertical-align: middle;
                        border: none;
                        box-shadow: none;
                        max-width: 250px;
                        word-break: break-word;
                        &:first-child {
                            border-top-left-radius: 8px;
                            border-bottom-left-radius: 8px;
                        }
                        &:last-child {
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                        }
                        .table-actions {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 0.5rem;
                            .dropdown {
                                &:focus-visible {
                                    outline: none;
                                }
                                .dropdown-toggle {
                                    padding: 2px;
                                    border-radius: 0.375rem;
                                    display: inline-flex;
                                    justify-content: center;
                                    color: $text-gray02;
                                    background-color: $secondary-color;
                                    border-color: $border-color;
                                    &::after {
                                        width: 24px;
                                        height: 24px;
                                        border: none;
                                        content: "";
                                        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjc1IDExLjk2ODhDMTMuNzUgMTAuOTAyMyAxMi44NDc3IDEwIDExLjc4MTIgMTBDMTAuNjg3NSAxMCA5LjgxMjUgMTAuOTAyMyA5LjgxMjUgMTEuOTY4OEM5LjgxMjUgMTMuMDYyNSAxMC42ODc1IDEzLjkzNzUgMTEuNzgxMiAxMy45Mzc1QzEyLjg0NzcgMTMuOTM3NSAxMy43NSAxMy4wNjI1IDEzLjc1IDExLjk2ODhaTTE2LjU5MzggMTBDMTUuNSAxMCAxNC42MjUgMTAuOTAyMyAxNC42MjUgMTEuOTY4OEMxNC42MjUgMTMuMDYyNSAxNS41IDEzLjkzNzUgMTYuNTkzOCAxMy45Mzc1QzE3LjY2MDIgMTMuOTM3NSAxOC41NjI1IDEzLjA2MjUgMTguNTYyNSAxMS45Njg4QzE4LjU2MjUgMTAuOTAyMyAxNy42NjAyIDEwIDE2LjU5MzggMTBaTTYuOTY4NzUgMTBDNS44NzUgMTAgNSAxMC45MDIzIDUgMTEuOTY4OEM1IDEzLjA2MjUgNS44NzUgMTMuOTM3NSA2Ljk2ODc1IDEzLjkzNzVDOC4wMzUxNiAxMy45Mzc1IDguOTM3NSAxMy4wNjI1IDguOTM3NSAxMS45Njg4QzguOTM3NSAxMC45MDIzIDguMDM1MTYgMTAgNi45Njg3NSAxMFoiIGZpbGw9IiM5NTlFQUUiLz4KPC9zdmc+Cg==");
                                    }
                                    &:focus {
                                        box-shadow: none;
                                        background-color: $light-border;
                                    }
                                    &:hover {
                                        background-color: $light-border;
                                    }
                                }
                                .dropdown-menu {
                                    background-color: $white;
                                    border: 1px solid $border-color;
                                    border-radius: 0.5rem;
                                    padding: 4px 0;
                                    .menu-options {
                                        padding: 0;
                                        .dropdown-item {
                                            color: $text-gray;
                                            font-size: 13px;
                                            line-height: 24px;
                                            font-weight: $font-semibold;
                                            display: flex;
                                            align-items: center;
                                            gap: 0.5rem;
                                            svg {
                                                * {
                                                    fill: $text-gray;
                                                }
                                            }
                                            &:hover,
                                            &:focus,
                                            &:active,
                                            &:active:focus {
                                                background-color: rgba($color: $info-color, $alpha: 0.06);
                                                color: $info-color;
                                                svg {
                                                    * {
                                                        fill: $info-color;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .custom-table {
                                    .table-header {
                                        display: flex;
                                        justify-content: space-between;
                                        .Buttons:nth-child(1) {
                                            margin-left: auto !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .react-bootstrap-table-pagination {
            align-items: center;
            @media (max-width:1024.98px) {
                flex-direction: column;
                align-items: flex-start;
                .react-bootstrap-table-pagination-list{
                    display: flex;
                    justify-content: flex-start;
                    @media (max-width:992.98px) {
                        .pagination{
                            .page-item{
                                button{
                                    padding: 0.1rem 0.5rem;
                                    min-height: 1.875rem;
                                    min-width: 1.875rem;
                                }
                            }
                        }
                    }
                }
            }
        }
        .pagination {
            display: flex;
            justify-content: flex-end;
            margin: 0;
            gap: 0.5rem;
            .page-item {
                button,
                .page-link {
                    border-radius: 0.5rem;
                    background-color: $white;
                    color: $light-gray;
                    font-weight: $font-semibold;
                    font-size: 0.875rem;
                    line-height: 1.5rem;
                    padding: 0.688rem;
                    min-height: 2.875rem;
                    min-width: 2.875rem;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    &:hover,
                    &:focus {
                        color: $info-color;
                    }
                }
                &.active {
                    button,
                    .page-link {
                        background-color: $info-color;
                        color: $white;
                        border: none;
                    }
                }
            }
        }
        .search-badges {
            .badges {
                display: flex;
                flex-flow: row;
                align-items: center;
                gap: 8px;
                flex-wrap: wrap;
                .badge {
                    background-color: $white;
                    border: 1px solid $border-color;
                    border-radius: 8px;
                    font-weight: $font-semibold;
                    font-size: 13px;
                    line-height: 24px;
                    color: $text-gray;
                    display: inline-flex;
                    align-items: center;
                    text-transform: capitalize;
                    gap: 4px;
                        svg {
                            cursor: pointer;
                        }
                }
            }
        }
    }
    .slider-btn{
        height: 100%;
        margin-bottom: -32px;
        .Buttons{
            display: flex;
            align-items: end;
            button.btn.btn-secondary-gray{
                width: 46px;
                height: 46px;
            }
        }
    }
    .filter-container{
        .search-container{
            width: 248px;
        }
        .dropdown-menu{
            min-width: 100%;
            width: 100%;
            .dropdown-item{
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
