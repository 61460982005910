@import "/src/Theme/custom.scss";

.chips {
    margin-top: 1rem;
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    .badge {
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: 8px;
        font-weight: $font-semibold;
        font-size: 13px;
        line-height: 24px;
        color: $text-gray;
        display: inline-flex;
        align-items: center;
        gap: 5px;
        max-width: 100%;
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        svg {
            cursor: pointer;
            &:hover {
                circle {
                    fill: $white;
                }
            }
        }
    }
}