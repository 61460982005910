@import "/src/Theme/custom.scss";

@mixin default-button-dropdown {
    .btn-outline-secondary {
        color: $light-gray;
        height: 100%;
        border-radius: 8px;
        min-width: 248px;
        border: 1px solid $border-color;
        font-weight: $font-semibold;
        font-size: 13px;
        line-height: 24px;
        background-color: $white;
        text-align: left;
        justify-content: flex-start;
        text-transform: capitalize;
        @media only screen and (max-width: $sm-screen) {
            min-width: 200px;
        }
        &.dropdown-toggle {
            position: relative;
            padding-right: 24px;
            &::after {
                margin: 0;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &:hover,
        &:focus {
            box-shadow: none;
        }
    }

    .show {
        .dropdown-menu {
            border: 1px solid $border-color;
            border-radius: 10px;
            width: 100%;
            padding: 0.25rem 0;
            overflow: hidden;
            overflow-y: auto;
            max-height: 185px;
            & > div {
                .menu-options {
                    border-bottom: 1px solid $border-color;
                }
                &:last-child {
                    .menu-options {
                        border-bottom: none;
                    }
                }
            }
        }
        .menu-options {
            padding: 5px 0.75rem;
            .dropdown-item {
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                color: $text-gray;
                padding: 0.25rem;
                &:hover {
                    background-color: unset;
                }
            }
            &:hover {
                background-color: rgba(242, 113, 116, 0.06);
                svg {
                    * {
                        fill: $primary-color;
                    }
                }
                .dropdown-item {
                    color: $primary-color;
                }
            }
        }
    }
    .dropdown-toggle {
        &.btn-secondary-gray {
            padding: 0.7rem 1.5rem;
            &:focus {
                box-shadow: -2px 0px 3px rgba(255, 255, 255, 0.6), 3px 3px 4px rgba(97, 97, 97, 0.12);
            }
            &::after {
                margin-left: 10px;
            }
            & ~ .dropdown-menu {
                background-color: $secondary-color;
                box-shadow: -2px 0px 3px rgba(255, 255, 255, 0.6), 3px 3px 4px rgba(97, 97, 97, 0.12);
                border: none;
                border-radius: 0.5rem;
                margin-top: 4px;
                max-height: 13rem;
                overflow: hidden;
                overflow-y: auto;
                .dropdown-item {
                    font-size: 13px;
                    font-weight: $font-semibold;
                    color: $text-gray;
                    padding: 0.5rem 0.75rem;
                    &:hover,
                    &:focus,
                    &:active {
                        color: $info-color;
                        background-color: $gray-border;
                    }
                    &.active {
                        color: $info-color;
                        background-color: $gray-border;
                    }
                }
            }
            &.btn-sm {
                padding: 0.2rem 0.725rem;
            }
        }
    }
}

.DropDown {
    .dropdown,
    .button-dropdown {
        width: auto;
        height: 3rem;
        .button-dropdown {
            @include default-button-dropdown;
        }
        &.form-sm {
            height: 2.25rem;
            .dropdown-menu {
                padding: 0.25rem 0;
                max-height: 13rem;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
    .default {
        .dropdown,
        .button-dropdown {
            &.form-sm {
                .btn-outline-secondary {
                    min-width: 110px;
                    padding-left: 0.375rem;
                }
                .dropdown-menu {
                    min-width: 110px;
                    max-height: 13rem;
                    overflow: hidden;
                    overflow-y: auto;
                }
            }
        }
    }
    .button-dropdown-with-option-icon {
        height: auto;
        .toggle-button {
            width: 100%;
            background-color: unset !important;
            color: $primary-color !important;
            font-size: 11px;
            line-height: 13px;
            font-weight: $font-semibold;
            border: 1px solid $border-color;
            padding: 0.15rem 0.5rem;
            &::after {
                display: none;
            }
            &:hover,
            &:focus,
            &:active,
            &:focus-visible {
                color: $info-color !important;
                background-color: unset !important;
                box-shadow: none !important;
                svg {
                    * {
                        fill: $info-color !important;
                    }
                }
            }
            svg {
                min-width: 24px;
                * {
                    fill: $primary-color !important;
                }
            }
        }
        .show {
            padding: 5px;
            .active {
                background: rgba(242, 113, 116, 0.06);
                color: $primary-color;
                svg {
                    * {
                        fill: $primary-color !important;
                    }
                }
            }
            .dropdown-item {
                padding: 6px 10px;
                gap: 8px;
                font-size: 0.813rem;
                line-height: 1.5rem;
                font-weight: $font-semibold;
                color: $text-gray;
                svg {
                    margin-right: 5px;
                    * {
                        fill: $text-gray !important;
                    }
                }
                &:hover,
                &:focus,
                &:active,
                &.active {
                    background-color: rgba(242, 113, 116, 0.06);
                    color: $primary-color;
                    svg {
                        * {
                            fill: $primary-color !important;
                        }
                    }
                }
            }
        }
    }
    .default {
        .dropdown {
            @include default-button-dropdown;
        }
    }
    .quick-promotions-image-select {
        padding: 0.313rem 0.563rem;
        margin: 0;
        position: relative;
        svg {
            width: 24px;
            height: 24px;
            margin: auto;
        }

        &::after {
            position: absolute;
            opacity: 0;
        }
    }
}
.quickPromotionList{
    .DropDown{
        .dropdown{
            height: 2.25rem;
            &.show{
                .dropdown-toggle{
                    &.btn.btn-primary{
                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
            }
            .dropdown-menu{
                max-height: 13rem;
                overflow: hidden;
                overflow-y: auto;
                &.promotion-select-dropdown{
                    padding: 0;
                    border-radius: 0.5rem;
                    background-color: $white;
                    border: 1px solid $border-color;
                    overflow: hidden;
                    margin-top: 2px;
                    width: 11.875rem;
                    max-height: 15rem;
                    overflow: hidden;
                    overflow-y: auto;
                    .bg-secondary{
                        padding: 0.625rem;
                        border-bottom: 1px solid $border-color;
                        position: sticky;
                        top: 0;
                        z-index: 1;
                    }
                    .dropdown-item{
                        padding: 0.5rem 0.625rem;
                        border-bottom: 1px solid $border-color;
                        background-color: $white;
                        outline: none;
                        position: relative;
                        &:hover,&:focus,&:active,&.active{
                            background-color: $secondary-color;
                        }
                        .avatars{
                            .quick-promotion-list{
                                width: 24px;
                                min-width: 24px;
                                height: 28.35px;
                                max-height: 30px;
                                object-fit: cover;
                                &.size-9x4{
                                    height: 10.68px;
                                }
                                &.size-8x10{
                                    height: 30px;
                                }
                                &.size-10x6{
                                    height: 14.21px;
                                }
                                &.size-7x3{
                                    height: 10.42px;
                                }
                                &.size-9x10{
                                    height: 26.78px;
                                }
                            }
                        }
                        .quick-promo-list-upload{
                            width: 24px;
                            min-width: 24px;
                            height: 28.35px;
                            max-height: 30px;
                            overflow: hidden;
                            border-radius: 2px;
                            background-color: rgba($color: $info-color, $alpha: 0.3);
                            input[type="file"]{
                                position: absolute;
                                opacity: 0;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                padding: 0;
                                border: none;
                            }
                            &.size-9x4{
                                height: 10.68px;
                            }
                            &.size-8x10{
                                height: 30px;
                            }
                            &.size-10x6{
                                height: 14.21px;
                            }
                            &.size-7x3{
                                height: 10.42px;
                            }
                            &.size-9x10{
                                height: 26.78px;
                            }
                        }
                        &:last-child{
                            border-bottom: none;
                        }
                    }
                    .quick-promotions-list-items{
                        width: 24px;
                        height: 28.35px;
                        &.size-9x4{
                            height: 10.68px;
                        }
                        &.size-8x10{
                            height: 30px;
                        }
                        &.size-10x6{
                            height: 14.21px;
                        }
                        &.size-7x3{
                            height: 10.42px;
                        }
                        &.size-9x10{
                            height: 26.78px;
                        }
                    }
                }
            }
        }
    }
}
