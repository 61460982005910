.scroll-component {
    .infinite-scroll-component__outerdiv{
        height: 100%;
    }
    .infinite-scroll-component{
        &.infinite-scroller{
            overflow: hidden !important;
            overflow-y: auto !important;
        }
    }
}
