@import "/src/Theme/custom.scss";

.drawer{
    width: auto;
}

.offcanvas-backdrop.show{
    opacity: 0.2;
}
.offcanvas{
    &.offcanvas-end{
        width: 450px;
        border: none;
        box-shadow: -5px 0px 20px rgba(0, 0, 0, 0.16);
    }
    .offcanvas-header{
        border-bottom: 1px solid $gray-border;
        .offcanvas-title{
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: $font-bold;
        }
        .btn-close{
            background: none;
            padding: 0;
            width: 2.813rem;
            height: 1rem;
            position: relative;
            line-height: 1rem;
            box-shadow: none;
            outline: none;
            &:hover,&:focus{
                opacity: 1;
            }
            &::after{
                content: 'Close';
                font-size: 1rem;
                font-weight: $font-medium;
                line-height: 1rem;
                color: $info-color;
            }
        }
    }
    .offcanvas-footer{
        border-top: 1px solid $gray-border;
        button{
            width: 100%;
        }
    }
}