@import "/src/Theme/custom.scss";

.customer-details {
    width: 100%;

    .DropDown .Buttons .btn {
        padding: 0.25rem 1.25rem;
    }

    .title {
        svg {
            * {
                fill: $primary-color;
            }
        }
    }
}

.custom-loader {
    @mixin common-placeholder($height, $border) {
        height: $height;
        border-radius: $border;
    }

    .w-60 {
        width: 60%;
    }

    .avatar-loader {
        .placeholder {
            width: 90px;
            @include common-placeholder(90px, 50px);
        }
    }

    .name-loader {
        width: 100%;

        .placeholder {
            @include common-placeholder(25px, 4px);
        }
    }

    .sub-name-loader {
        width: 100%;

        .placeholder {
            @include common-placeholder(16px, 4px);
        }
    }

    .small-loader {
        width: 100%;

        .placeholder {
            @include common-placeholder(9px, 4px);
        }
    }
}

.creditHold-dropdown {
    .DropDown {
        .button-dropdown-with-option-icon {
            button {
                svg {
                    * {
                        fill: transparent !important;
                    }

                }

                &:hover {
                    svg * {
                        fill: transparent !important;
                    }
                }
            }

        }
    }
}
.dropdown-right{
    .button-dropdown-with-option-icon{
        &.show{
            .dropdown-menu{
                inset: unset !important;
                transform: unset !important;
                right: 0 !important;
                margin-top: 3px;
            }
        }
    }
}

// badge

.badge-status{
    height: 26px;
    border-radius: 5px;
    &.red-pink{
        background: #F27174;
    }
    &.purple{
        background: #C257F8;
    }
    &.orange{
        background: #FF7A36;
    }
    &.cyan{
        background: #22c5aa;
    }
    &.blue{
        background: #1e73e3;
    }
    &.success{
        background: #01BE83;
    }
}
.status-label, .badge-outer{
    min-width: 112px;
}
.mr-lg-38{
    margin-right: 38px;
}