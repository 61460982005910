// @use "/src/Theme/custom.scss" as theme;
@import "/src/Theme/custom.scss";

.Toastify{
    .Toastify__toast-container{
        .Toastify__toast{
            background-color: $white;;
            color: $text-gray;
            font-weight: $font-semibold;
            font-size: 0.875rem;
            line-height: 1.375rem;
            border: 1px solid $border-color;
            border-radius: 1rem;
            position: relative;
            overflow: visible;
            .Toastify__toast-body{
                .Toastify__toast-icon{
                    width: 3.375rem;
                    height: 3.375rem;
                    min-width: 3.375rem;
                    svg{
                        width: 100%;
                        object-fit: contain;
                    }
                }
            }
            .Toastify__close-button{
                background-color: $secondary-color;
                position: absolute;
                width: 1.375rem;
                height: 1.375rem;
                min-width: 1.375rem;
                left: -10px;
                top: -10px;
                border: 1px solid $info-color;
                color: $info-color;
                border-radius: 1.375rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                opacity: 1;
                svg{
                    object-fit: contain;
                }
            }
            .Toastify__progress-bar{
                // display: none;
                opacity: 0 !important;
            }
            &.Toastify__toast--info{
                .Toastify__toast-body{
                    .Toastify__toast-icon{
                        svg{
                            fill: $info-color;
                        }
                    }
                }
            }
            &.Toastify__toast--success{
                .Toastify__toast-body{
                    .Toastify__toast-icon{
                        svg{
                            fill: $success-color;
                        }
                    }
                }
            }
            &.Toastify__toast--error{
                .Toastify__toast-body{
                    .Toastify__toast-icon{
                        svg{
                            fill: $danger-color;
                        }
                    }
                }
            }
            &.Toastify__toast--warning{
                .Toastify__toast-body{
                    .Toastify__toast-icon{
                        svg{
                            fill: $gold;
                        }
                    }
                }
            }
        }
    }
}