@import "/src/Theme/custom.scss";

.chat {
    width: 100%;
    padding: 2rem 3rem;
    @media only screen and (max-width: $sm-screen) {
        padding: 1.5rem;
    }
    @media only screen and (max-width: $xs-screen) {
        padding: 1rem;
    }
    .chat-trigger{
        .cards{
            height: 100%;
            .card{
                background-color: $white;
                border-radius: 0.5rem;
                .card-body{
                    height: 100%;
                    display: flex;
                    flex-flow: column;
                    .chat-trigger-container{
                        .card{
                            background-color: $medium-border;
                            .card-body{
                                padding: 3px;
                                .chat-head{
                                    height: 100%;
                                    display: flex;
                                    flex-flow: column;
                                    justify-content: flex-start;
                                    .cards{
                                        height: auto;
                                        display: flex;
                                        flex-flow: column;
                                        .card{
                                            &.chat-each{
                                                background-color: $white;
                                                transition: 0.3s ease all;
                                                padding: 0.625rem;
                                                border: none;
                                                border-radius: 0.375rem;
                                                margin-bottom: 3px;
                                                @media only screen and (max-width: $lg-screen) {
                                                    padding: 0.4rem;
                                                }
                                                &:hover {
                                                    background-color: $secondary-color;
                                                }
                                                &.opened-chat {
                                                    background-color: $border-color;
                                                }
                                                &.active-chat {
                                                    background-color: $info-light;
                                                }
                                                &.online{
                                                    .avatars{
                                                        &::after{
                                                            width: 0.5rem;
                                                            height: 0.5rem;
                                                            border-radius: 0.8rem;
                                                            display: block;
                                                            content: '';
                                                            background-color: $success-color;
                                                            position: absolute;
                                                            right: 8px;
                                                            top: 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.flex-fill-row{
    height: 50%;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-flow: column;
}
.flex-fill-column{
    height: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-flow: column;
}
span.placeholder{
    border-radius: 3rem;
}