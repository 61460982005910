@import "/src/Theme/custom.scss";

.moreList{
    .search{
        .search-container{
            width: 100%;
        }
    }
    .moreListBody{
        max-height: calc(100vh - 300px);
        overflow: hidden;
        overflow-y: auto;
        .list-group{
            margin-bottom: 0.75rem;
            &:last-child{
                margin-bottom: 0;
            }
            .list-group-item{
                border: none;
                padding: 0;
                font-size: 0.938rem;
                line-height: 1.4rem;
            }
        }
    }
}