@import "./Theme/custom.scss";

:root{
  font-size: 16px;
  line-height: 24px;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

.btn-primary,.btn-primary-shadow {
    @include button-variant($primary-color, $primary-color, $white, lighten($primary-color, 5%), lighten($primary-color, 3%), $white);
}
.link-secondary {
    @include button-variant(transparent, transparent, $light-gray, transparent, transparent, $text-gray02);
}
.link-info {
  @include button-variant(transparent, transparent, $info-color, transparent, transparent, $primary-color);
}
.btn-info {
    @include button-variant($info-color, $info-color, $white, darken($info-color, 5%), darken($info-color, 5%), $white);
}
.btn-link {
    @include button-variant(transparent, transparent, $primary-color, transparent, transparent, darken($primary-color, 5%));
}
.btn-secondary,.btn-secondary-shadow {
    @include button-variant($secondary-color, $secondary-color, $primary-color, $secondary-color, $secondary-color, lighten($primary-color, 5%));
}
.btn-secondary-gray{
    @include button-variant($secondary-color, $secondary-color, $text-gray02, $secondary-color, $secondary-color, $text-gray);
}
.btn-secondary-md{
  @include button-variant($secondary-color, $border-color, $text-gray, $secondary-color, $secondary-color, $text-gray02);
}
.btn-outline-primary{
  @include button-variant($white, $border-color, $primary-color, $secondary-color, $secondary-color, $primary-color);
}

.btn-outline-white{
  @include button-variant($white, $border-color, $text-gray02, $secondary-color, $border-color, $info-color);
}
.btn-outline-gray{
  @include button-variant($secondary-color, $border-color, $text-gray02, $secondary-color, $border-color, $text-gray);
}

// Scroll Bar
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  @media only screen and (max-width: $xs-screen){
      width: 4px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: $light-gray;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $info-color;
  border-radius: 10px;
  box-shadow: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}



// Text Colors
.color-primary{ color: $primary-color;}
.color-info{ color: $info-color;}
.color-dark01{ color: $dark01;}
.color-gray{ color: $text-gray;}
.color-gray02{ color: $text-gray02;}
.color-gray03{ color: $text-gray03;}
.color-lightgray{ color: $light-gray;}
.color-black{ color: $black;}
.color-white{ color: $white;}

.color-success{color:$success-color;}
.color-blue{color:$light-blue;}
.color-purple{color:$purple01;}
.color-orange{color:$warning-color;}
.color-danger{color:$danger-color;}

.bg-body{background-color: $secondary-color;}
.bg-delivered{background-color: $blue;}
.bg-gray{background-color: $light-gray;}


// Font Weight
.font-light{font-weight: $font-light;}
.font-regular{font-weight: $font-regular;}
.font-medium{font-weight: $font-medium;}
.font-semibold{font-weight: $font-semibold;}
.font-bold{font-weight: $font-bold;}
.font-extrabold{font-weight: $font-extrabold;}


// Background Colors
.bg-primary{background-color: $primary-color;}
.bg-secondary{
  background-color: $secondary-color !important;
  &.badge{
    background-color: $border-color !important;
  }
}
.bg-offwhite{background-color: $light-border;}
.bg-success{background-color: $success-color;}
.bg-danger{background-color: $danger-color;}
.bg-warning{background-color: $gold;}
.bg-info{background-color: $info-color;}
.bg-light{background-color: $light-border;}
.bg-dark{background-color: $dark01;}
.bg-blue{background-color: $blue;}
.bg-purple{background-color: $purple;}
.bg-cyan{background-color: $cyan;}
.bg-pink{background-color: $pink;}

.bg-blue01{background-color:$light-blue;}
.bg-purple01{background-color:$purple01;}
.bg-orange01{background-color:$warning-color;}


// Misc
.min-24{min-width: 24px;}
.rounded-4{border-radius: 0.5rem;}
.shadow-card{box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.04);}
.card-divider{margin: 1rem 0; background-color: unset; border-bottom: 1px dashed $border-color; opacity: 1;}
.border-medium{border-color: $medium-border !important;}
.badge{
    padding: 0.4rem 0.625rem;
    font-weight: $font-semibold;
    font-size: 0.75rem;
    &.badge-lg{
      font-size: 1rem;
      line-height: 1.35rem;
    }
    &.badge-xs{
      font-size: 10px;
      line-height: 0.85rem;
      padding: 0.25rem 0.5rem;
    }
}
svg{
  &.icon-16{
    width: 16px;
    height: 16px;
    min-width: 16px;
  }
}

// Form Control
.form-control{
  &.form-sm{
    height: 2.25rem;
    border-radius: 8px;
    padding: 0.375rem 0.5rem;
    font-size: 13px;
    border: 1px solid $border-color;
    font-weight: $font-semibold;
    &::placeholder{
      color: $light-gray;
    }
  }
  &:focus{
    box-shadow: none;
    border-color: $info-color;
  }
}
textarea.form-control,textarea.form-control:focus{
  border-radius: 0.625rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: $font-semibold;
  color: $text-gray;
}
.form-label{
  font-size: 0.813rem;
  line-height: 1.25rem;
  color: $dark01;
  font-weight: $font-semibold;
  &.color-gray02{
    @extend .color-gray02;
  }
}

.form-error{
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: $font-semibold;
  color: $primary-color;
  margin-top: 0.25rem;
  display: inline-block;
}

.gray-form{
  .form-label{
    color: $text-gray;
    margin-bottom: 0.25rem;
  }
  .form-control{
    background-color: $form-disabled;
    border-color: $border-color;
    border-radius: 0.5rem;
    padding: 0.695rem 0.75rem;
    font-weight: $font-semibold;
    font-size: 0.813rem;
    &::placeholder{
      color: $light-gray;
      font-weight: $font-semibold;
      font-size: 0.813rem;
    }
  }
  .input-group{
    .input-group-text{
      background-color: $form-disabled;
      border-color: $border-color;
      border-radius: 0.5rem;
      color: $light-gray;
      font-weight: $font-semibold;
      font-size: 1.125rem;
      border-right: none;
      padding: 0.375rem 0.25rem 0.375rem 1rem;
    }
    .form-control{border-left: none;}
  }
}
.overflow-x-auto{overflow-x: auto !important;}
.overflow-y-auto{overflow-y: auto !important;}
.dropdown{
  &.link-secondary{
    .dropdown-toggle.btn-primary{
      @include button-variant(transparent, transparent, $light-gray, transparent, transparent, $text-gray02);
      font-weight: $font-semibold;
      font-size: 0.875rem;
      display: inline-flex;
      align-items: center;
      box-shadow: none;
      &:focus,&:active{
        box-shadow: none;
        outline: none;
      }
    }
  }
  .dropdown-menu {
    border: 1px solid $border-color;
    border-radius: 10px;
    padding: 3px 0;
  }
  .menu-options {
      padding: 0;
      .dropdown-item {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          padding: 0.5rem 0.75rem;
          color: $text-gray;
          border-bottom: 1px solid $border-color;
          &:last-child{border-bottom: none;}
          &:hover {
            background-color: rgba(242, 113, 116, 0.06);
            color: $primary-color;
            svg {
                * {
                    fill: $primary-color;
                }
            }
          }
      }
  }
}

.flex-fill-column{
  height: 50%;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
}
.overflow-y-auto{overflow-y: auto !important;}

// Change Profile Image
.profile-image-container{
  position: relative;
  .profile-image-overlay{
      width: 6.75rem;
      min-width: 6.75rem;
      height: 6.75rem;
      border-radius: 6.75rem;
      transition: all 0.3s ease;
      display: flex;
      position: absolute;
      margin: 0 auto;
      top: 0;
      left: 0;
      right: 0;
      background-color: rgba(149, 158, 174, 0.6);
      opacity: 0;
      align-items: center;
      justify-content: center;
      input[type="file"]{
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 6.75rem;
        cursor: pointer;
      }
      .removeImage{
        width: 2rem;
        height: 2rem;
        background-color: $danger-color;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
        color: $white;
        opacity: 0;
        @include transition(0.3s);
        position: absolute;
        right: 0;
        top: 0.25rem;
        cursor: pointer;
      }
      &:hover,&:focus{
        opacity: 1;
        backdrop-filter: blur(2px);
        .removeImage{
          opacity: 1;
        }
      }

  }
  &.default-image{
    .profile-image-overlay{
      .removeImage{
        display: none;
      }
    }
  }
}