@import "/src/Theme/custom.scss";
.search {
    max-width: 100%;
    .search-container {
        min-width: 150px;
        width: 300px;
        max-width: 100%;
        height: 3rem;
        display: flex;
        border-radius: 8px;
        border: 1px solid $border-color;
        padding: 10px;
        background-color: $white;
        align-items: center;
        svg{
            min-width: 24px;
            *{
                fill: $primary-color;
            }
        }
        .input-group-text {
            background-color: transparent;
            border-left: 0;
            left: 0;
            z-index: 1;
            top: 0;
            bottom: 0;
            border: 0;
            svg {
                margin: auto;
            }
        }
        .form-control {
            border: none;
            color: $text-gray03;
            font-size: 14px;
            line-height: 22px;
            font-weight: $font-medium;
            padding-right: 0;
            padding-left: 5px;
            text-transform: capitalize;
            &:focus {
                box-shadow: unset;
                border: 0;
            }
            &::placeholder {
                color: $light-gray;
                font-size: 13px;
                font-weight: $font-medium;
                line-height: 22px;
            }
        }
    }
}
