@import "/src/Theme/custom.scss";

.search-select {
    .search-filter{
        position: relative;
    }
    .form-control {
        color: $text-gray;
        height: 3rem;
        border-radius: 8px;
        min-width: 248px;
        border: 1px solid $light-gray;
        font-weight: $font-semibold;
        font-size: 13px;
        line-height: 24px;
        background-color: $white;
        text-align: left;
        outline: none;
        box-shadow: none;
        padding: 0.375rem 0.75rem;
        &::placeholder {
            font-weight: $font-semibold;
            font-size: 13px;
            line-height: 24px;
            color: $light-gray;
        }
    }
    &.w-100{
        .form-control{
            min-width: 100%;
        }
    }
    ul {
        margin: 0;
        list-style: none;
        padding: 0 5px;
        position: absolute;
        width: 100%;
        border-radius: 8px;
        padding: 0 6px;
        background-color: $white;
        z-index: 2;
        margin-top: 3px;
        max-height: 240px;
        overflow: hidden;
        overflow-y: auto;
        li.search-list {
            margin: 3px 0;
            list-style: none;
            padding: 0.5rem;
            background-color: $white;
            font-weight: $font-semibold;
            font-size: 13px;
            line-height: 24px;
            border-radius: 8px;
            display: flex;
            flex-flow: row;
            cursor: pointer;
            .form-check {
                display: flex;
                flex-flow: row;
                align-items: center;
                margin: 0;
                cursor: pointer;
            }
            &:hover {
                background-color: rgba($color: $primary-color, $alpha: 0.16);
                color: $primary-color;
            }
            input[type="checkbox"] {
                width: 24px;
                height: 24px;
                border-radius: 3px;
                border: 1px solid $text-gray;
                margin-top: 0;
                margin-bottom: 0;
                margin-right: 0;
                outline: none;
                box-shadow: none;
                &:checked{
                    background-color: $primary-color;
                    border-color: $primary-color;
                }
            }
            &.active {
                background-color: $primary-color;
                color: $white;
                .custom-checkbox {
                    .labelled {
                        .form-check {
                            input[type="checkbox"] {
                                border: 1px solid $white;
                            }
                            .form-check-label {
                                color: $white;
                            }
                        }
                    }
                }
                &:hover {
                    color: $white;
                }
            }
        }
        .user-not-found {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            p {
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                margin: 10px;
            }
            color: $text-gray;
        }
    }
    .suggestion-close {
        display: flex;
        margin: 10px;
        justify-content: flex-end;
        z-index: 10;
        position: absolute;
        right: -3px;
        top: 0rem;
        svg {
            cursor: pointer;
            width: 25px;
            height: 25px;
        }
    }
    .custom-loader {
        position: absolute;
        z-index: 10;
        width: 100%;
        background-color: $white;
        padding: 0.5rem;
    }
}
