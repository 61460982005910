
@import "/src/Theme/custom.scss";

a{text-decoration: none; color: inherit;}
.Buttons,.DropDown {
    width: auto;
    .btn{
        font-weight: 600;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        outline: none;
        svg{
            margin-right: 5px;
            *{
                &.color-success{
                    fill: $success-color !important;
                }
            }
        }
        span{
            display: inline-block;
            margin-left: 3px;
            svg{margin-right: 0;}
        }
        &.px-0,&.px-1,&.px-2,&.p-0,&.p-1,&.p-2{
            svg{
                margin: 0;
            }
        }
        &.btn-shadow{
            box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
            &:hover,&:focus,&:active,&:focus:active{
                box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
            }
        }
        &.btn-sm{
            padding: 0.2rem 0.725rem;
        }
        &.icon-24{
            svg{
                width: 24px;
                height: 24px;
            }
        }
        &.icon-16{
            svg{
                width: 16px;
                height: 16px;
            }
        }
        &.promo-list-up-img{
            height: 2.25rem;
            width: 2.75rem;
        }
    }
    .btn-primary,.btn-primary-shadow,.btn-info{
        padding: 0.5rem 1.5rem;
        font-size: 13px;
        line-height: 24px;
        border-radius: 0.5rem;
        svg{
            *{
                fill: $white;
            }
        }
        &:disabled, &.disabled{
            opacity: 0.5;
            color: $white;
        }
        &:hover,&:focus,&:active,&:focus:active{
            box-shadow: none;
        }
    }
    .btn-info{
        &:active{
            color: $white;
        }
        &.color-primary{
            svg{
                *{
                    fill: $primary-color;
                }
            }
        }
        &:disabled, &.disabled{
            color: $white;
        }
    }

    .btn-outline-primary{
        svg{
            *{
                fill: $primary-color;
            }
        }
        &:hover,&:focus,&:active,&:focus:active{
            color: $primary-color;
            svg{
                *{
                    fill: $primary-color;
                }
            }
        }
        &:disabled, &.disabled{
            color: $primary-color;
        }
    }

    .btn-outline-secondary{
        color: $primary-color;
        background-color: $secondary-color;
        border-color: $border-color;
        svg{
            *{
                fill: $primary-color;
            }
        }
        &:hover,&:focus,&:active,&:focus:active{
            background-color: $primary-color;
            border-color: $primary-color;
            color: $white;
            svg{
                *{
                    fill: $white;
                }
            }
        }
        &:disabled, &.disabled{
            color: $white;
        }
    }

    .btn-outline-gray{
        padding: 2px;
        border-radius: 0.375rem;
        svg{
            margin: 0;
            *{
                fill: $text-gray02;
            }
        }
        &:hover,&:focus,&:active,&:focus:active{
            color: $text-gray;
            svg{
                *{
                    fill: $text-gray;
                }
            }
        }
    }
    .btn-outline-white{
        padding: 2px;
        border-radius: 0.375rem;
        svg{
            margin: 0;
            *{
                fill: $text-gray02;
            }
        }
        &:hover,&:focus,&:active,&:focus:active{
            color: $info-color;
            svg{
                *{
                    fill: $info-color;
                }
            }
        }
        &:disabled, &.disabled{
            color: $info-color;
        }
    }

    .btn-primary-lg{
        padding: 1rem 5rem;
        font-size: 15px;
        line-height: 24px;
        border-radius: 0.625rem;
        @media only screen and (max-width: $sm-screen) {
            padding: 1rem 2rem;
        }
    }
    .btn-primary-md,.btn-secondary-md{
        padding: 0.688rem 3rem;
        font-size: 15px;
        line-height: 24px;
        border-radius: 0.625rem;
        font-weight: $font-semibold;
        @media only screen and (max-width: $xs-screen) {
            padding: 0.688rem 0.75rem;
        }
        &.text-13{
            font-size: 0.813rem;
        }
    }
    .btn-secondary,.btn-secondary-shadow,.btn-secondary-gray{
        padding: 0.5rem 1.5rem;
        font-size: 13px;
        line-height: 24px;
        border-radius: 0.5rem;
        svg{
            *{
                fill: $primary-color;
            }
        }
        &:disabled, &.disabled{
            color: $info-color;
        }
        &:hover,&:focus,&:active,&:focus:active{
            box-shadow: none;
            color: $info-color;
            svg{
                *{
                    fill: $info-color;
                }
            }
        }
    }
    .btn-secondary-gray{
        svg{
            *{
                fill: $text-gray;
            }
        }
        &:hover,&:focus,&:active,&:focus:active{
            color: $text-gray02;
            svg{
                *{
                    fill: $text-gray02;
                }
            }
        }
        .count{
            min-width: 1.5rem;
            height: 1.5rem;
            border-radius: 1.5rem;
            background-color: $primary-color;
            color: $white;
            font-size: 0.813rem;
            line-height: 1.5rem;
            font-weight: $font-semibold;
            text-align: center;
            margin-left: 1rem;
            display: inline-block;
        }
    }
    .link-info{
        text-decoration: none;
        padding: 0;
        font-size: 14px;
        line-height: 24px;
        svg{
            *{
                fill: $info-color;
            }
        }
        &:hover,&:focus,&:active,&:focus:active{
            svg{
                *{
                    fill: $primary-color;
                }
            }
        }
        &.color-primary{
            svg{
                *{
                    fill: $primary-color;
                }
            }
        }
    }
    .link-secondary{
        text-decoration: none;
        padding: 0;
        font-size: 14px;
        line-height: 24px;
        svg{
            *{
                fill: $light-gray;
            }
        }
    }
    .link-dark{
        text-decoration: none;
        padding: 0;
        font-size: 14px;
        line-height: 24px;
        svg{
            *{
                fill: $dark01;
            }
        }
    }
    .btn-link{
        text-decoration: none;
        padding: 0 .5rem;
        font-size: 14px;
        line-height: 24px;
        &:focus,&:hover,&:focus:hover{
            text-decoration: underline;
            box-shadow: none;
        }
    }
    .btn-primary-shadow,.btn-secondary-shadow, .btn-secondary-gray{
        box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
        &:hover,&:focus,&:active,&:focus:active{
            box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
        }
        &:disabled,&.disabled{
            opacity: 0.5;
        }
    }

    .btn-white-rounded, .btn-primary-rounded,.btn-secondary-rounded{
        font-size: 24px;
        border-radius: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1)) drop-shadow(-1px -1px 1px rgba(255, 255, 255, 0.5));
        box-shadow: none;
        padding: 0.34rem;
        position: relative;

        svg{
            margin: 0;
        }
        &:hover,&:focus,&:active,&:focus:active{
            box-shadow: none;
            filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(-1px -1px 1px rgba(255, 255, 255, 0.5));
            svg{
                margin: 0;
            }
        }
        .count{
            display: block;
            min-width: 20px;
            height: 20px;
            border-radius: 30px;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
            position: absolute;
            top: -5px;
            right: -9px;
            font-size: 10px;
            font-weight: $font-medium;
            line-height: 20px;
            text-align: center;
            margin: 0;
            padding: 0 3px;
        }
        @media only screen and (max-width: $xl-screen) {
            padding: 0.325rem;
        }
    }

    .btn-white-rounded{
        background-color: $white;
        color: $primary-color;
        svg{
            *{
                fill: $primary-color;
            }
        }
        &:hover,&:focus,&:active,&:focus:active{
            color: $primary-color;
            svg{
                *{
                    fill: $primary-color;
                }
            }
        }
        .count{
            background-color: $primary-color;
            color: $white;
        }
    }

    .btn-primary-rounded{
        background-color: $primary-color;
        color: $white;
        svg{
            *{
                fill: $white;
            }
        }
        &:hover,&:focus,&:active,&:focus:active{
            color: $primary-color;
            svg{
                *{
                    fill: $white;
                }
            }
        }
        .count{
            background-color: $white;
            color: $primary-color;
        }
    }

    .btn-secondary-rounded{
        background-color: $secondary-color;
        color: $info-color;
        filter: none;
        svg{
            *{
                fill: $info-color;
            }
        }
        &:hover,&:focus,&:active,&:focus:active{
            color: $primary-color;
            filter: none;
            svg{
                *{
                    fill: $primary-color;
                }
            }
        }
        .count{
            background-color: $primary-color;
            color: $white;
        }
        &.btn-shadow{
            padding: 0.5rem;
            svg{
                *{
                    fill: $primary-color;
                }
            }
            &.active{
                background-color: $primary-color;
                svg{
                    *{
                        fill: $white;
                    }
                }
            }
        }
    }

    // Dropdown
    .add-new-unit{
        .btn.link-info{
            color: $text-gray;
            font-size: 0.813rem;
            svg{
                width: 1.25rem;
                height: 1.25rem;
                *{
                    fill: $text-gray;
                }
            }
            &:hover,&:focus,&:active,&:focus:active{
                color: $info-color;
                svg{
                    *{
                        fill: $info-color;
                    }
                }
            }
        }
    }
}
