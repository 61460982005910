@import "/src/Theme/custom.scss";
$sidebar-width  : 294px;
$sidebar-sm-width  : 95px;

.dashboard {
    display: flex;
    width: 100vw;
    background-color: $secondary-color;
    height: 100vh;
    .main {
        width: 100%;
        max-width: calc(100vw - $sidebar-width);
        height: 100%;
        min-height: 100vh;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        @media only screen and (max-width: $sm-screen) {
            max-width: calc(100vw - $sidebar-sm-width);
        }
    }
}
