@import "/src/Theme/custom.scss";

.custom-modal {
    .modal-content {
        position: relative;
        border-radius: 1rem;
        .modal-title {
            position: absolute;
            right: 12px;
            top: -46px;
            cursor: pointer;
        }
        .modal-body {
            padding: 0;
        }
    }
    &.modal-secondary{
        .modal-content{
            background-color: $secondary-color;
            padding: 1.25rem;
        }
    }
    .modal-dialog{
        &.modal-md{
            .modal-content{
                height: auto;
                padding: 3rem;
                .Buttons{
                    .btn{
                        &.btn-secondary-shadow,&.btn-primary-shadow{
                            padding-left: 1rem;
                            padding-right: 1rem;
                            min-width: 10rem;
                            &.width-sm{
                                min-width: 6rem;
                            }
                        }
                    }
                }
            }
        }
    }
    &.custom-padding{
        .modal-dialog{
            &.modal-md{
                .modal-content{
                    height: auto;
                    padding: 30px 20px 24px;
                }
            }
        }
    }
}
