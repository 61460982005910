@import "/src/Theme/custom.scss";
.stepper {
    width: 100%;
    .order-status {
      padding: 15px;
        .completed {
            background-image: url("../../../public/images/tick.svg");
            background-repeat: no-repeat;
            color: transparent;
            background-position: center;
            background-size: 18px;
            span {
                display: none;
            }
        }
    }

    // Promotion
    .promotion-form{
        @media only screen and (max-width: $sm-screen){
            padding: 1rem 0 1rem;
        }
        [class^='StepContainer']{
            @media only screen and (max-width: $xs-screen){
                padding: 0;
            }
        }
        [class^='ConnectorContainer']{
            left: calc(-50% + 2rem);
            right: calc(50% + 2rem);
            position: absolute;
            top: calc(-2px + 2rem);
            span[class^='Connector']{
                display: block;
                border-top: 4px solid rgb(189, 189, 189);
            }
            @media only screen and (max-width: $md-screen){
                top: calc((2.5rem - 4px) / 2);
                left: calc(-50% + 1.25rem);
                right: calc(50% + 1.25rem);
            }
        }
        button.promotion-form-step{
            width: 4rem;
            border: none;
            cursor: pointer;
            height: 4rem;
            margin: 0px auto;
            display: flex;
            padding: 0px;
            font-size: 1em;
            align-items: center;
            border-radius: 50%;
            justify-content: center;
            background-color: $border-color;
            @media only screen and (max-width: $md-screen){
                width: 2.5rem;
                height: 2.5rem;
            }
            span{
                color: $silver;
                font-family: 'Montserrat', sans-serif;
                font-size: 1.5rem;
                line-height: 1.5rem;
                font-weight: $font-semibold;
                @media only screen and (max-width: $md-screen){
                    font-size: 1rem;
                    line-height: 1rem;
                }
            }
            &.active{
                background-color: $info-color;
                span{
                    color: $white;
                }
                &~[class^='LabelContainer']{
                    span{
                        color: $info-color;
                    }
                }
            }
            &.completed{
                background-color: $primary-color;
                background-image: url("../../../public/images/tick.svg");
                background-repeat: no-repeat;
                color: transparent;
                background-position: center;
                background-size: 2rem;
                @media only screen and (max-width: $md-screen){
                    background-size: 1rem;
                }
                span {
                    display: none;
                }
                &~[class^='LabelContainer']{
                    span{
                        color: $primary-color;
                    }
                }
            }
            &~[class^='LabelContainer']{
                span{
                    font-size: 1.125rem;
                    font-family: 'Montserrat', sans-serif;
                    font-weight: $font-semibold;
                    line-height: 1.5rem;
                    color: $silver;
                    margin-top: 0.5rem;
                    @media only screen and (max-width: $md-screen){
                        font-size: 0.875rem;
                        line-height: 0.875rem;
                    }
                }
            }
        }
    }
  
}
