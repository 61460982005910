.spinners{
    display: flex;
    height: 97vh;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.button-spinner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}