@import "/src/Theme/custom.scss";

.customer-card{
    width: 100%;
    &.dashboard-head-card{
        flex: 1 1 auto;
        width: auto;
    }
    .card{
        border-radius: 8px;
        padding: 0;
        background-color: $white;
        border: none;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.04);
        &.bg-secondary{
            border: 1px solid $medium-border;
            box-shadow: none;
        }
    }
}