@import "/src/Theme/custom.scss";

.not-found{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    h1{
        color: $primary-color;
    }
}